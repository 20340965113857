import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ButtonRow from "../components/button_row";
import Button from "../components/button";
import Layout from "../components/layout";

import stavka from "../images/stavka.svg";
import prviMaj from "../images/prvi_maj.svg";
import checklist from "../images/checklist.svg";

const query = graphql
`
query ulica {
  allMarkdownRemark(filter: {frontmatter: {path: {eq: "/na_ulice"}}}) {
    edges {
      node {
        id
        frontmatter {
          path
          navTitle
        }
        excerpt
        html
        rawMarkdownBody
      }
    }
  }
}
`;

const UlicaPage = props => (
  <Layout navTitle={props.data.frontmatter.navTitle}>
    <div dangerouslySetInnerHTML={{ __html: props.data.html }}/>

    <ButtonRow>
      <Button
        img={checklist}
        flavorText="Protestiraj varno"
        link="/na_ulice/protestiraj_varno"
      />

      <Button
        img={prviMaj}
        flavorText="1. Maj!"
        link="/na_ulice/prvi_maj"
      />
    
      <Button
        img={stavka}
        flavorText="Najemniška stavka"
        link="/na_ulice/stavka"
      />
    </ButtonRow>
  </Layout>
);

export default () => (
  <StaticQuery
    query={query}
    render={ data => (
      <UlicaPage data={data.allMarkdownRemark.edges[0].node}/>
    )}
  />
);
